export const ACCOUNT_OPTIONS = [
  {
    value: 'unsubscribedProductTips',
    label: 'Get daily product tips and learn about the best practices of the tool',
  },
  {
    value: 'unsubscribedErrorNotification',
    label: 'Get an alert when an error occurs during the execution of my tasks',
  },
  {
    value: 'unsubscribedScheduleReminder',
    label: 'Get a reminder if my extension is not online just before my scheduled tasks begin',
  },
  {
    value: 'unsubscribedMissedTasks',
    label: "Receive an alert if my scheduled tasks are missed because my extension wasn't online",
  },
  {
    value: 'unsubscribedNewRelease',
    label: 'Get a notification when a new version of the extension has been released',
  },
] as const;

export const AFFILIATE_OPTIONS = [
  {
    value: 'unsubscribedAffiliateNewPayment',
    label: 'Receive a notification when a payment is received from my new referrals',
  },
  {
    value: 'unsubscribedAffiliateSubPayment',
    label: 'Receive a notification when a recurring payment is received from my existing referrals',
  },
  {
    value: 'unsubscribedAffiliateRefund',
    label: 'Receive a notification when a payment is refunded to my referrals',
  },
] as const;
