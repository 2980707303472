// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authInitialize,
  getAppVersionsRequest,
  setExtensionVersion,
} from './store/dashboard/slices/authSlice';
import { loadCrispChat } from './utils/crispChatHelpers';
import useResponsive from './hooks/useResponsive';
import { Navigate, useLocation } from 'react-router';
import { PATH_AUTH, PATH_DASHBOARD } from './routes/paths';
import { IRootState } from './store';
import moment from 'moment';
import axiosInstance, { setHeader } from './utils/axios';
import { crosslistEndpoints } from './endpoints';
import { isLoggedIn } from './utils/isLoggedIn';
import { setSession } from './auth/utils';
import { BackgroundActionsEnum } from './utils/ExtensionMessageChannel';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'sm');
  const { pathname } = useLocation();
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { refreshToken, email, fullName, proStatus, blockState, userId, referrerCode, loggedIn } =
    isLoggedIn();

  const getExtensionVersion = async () => {
    const data = await window.extension.sendCommand(BackgroundActionsEnum.GET_EXTENSION_VERSION);
    return data;
  };

  // We must check this here because we can't dispatch actions in ExtensionMessageChannel.ts
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const checkExtensionAndGetVersion = async () => {
      if (window.extension?.isConnected) {
        try {
          const data = await getExtensionVersion();
          if (data.result) {
            dispatch(setExtensionVersion(data.result));
            console.info('Extension version:', data.result);

            if (intervalId) {
              clearInterval(intervalId);
              intervalId = null;
            }
          }
        } catch (error) {
          console.error('Error getting extension version:', error);
        }
      }
    };

    checkExtensionAndGetVersion();

    intervalId = setInterval(checkExtensionAndGetVersion, 500);

    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(authInitialize());
    dispatch(getAppVersionsRequest());
    loadCrispChat(dispatch);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      axiosInstance
        .get(crosslistEndpoints.auth.GET_REFRESH_ACCESS_TOKEN(refreshToken))
        .then((res) => {
          setSession(
            res.data.accessToken,
            res.data.refreshToken,
            email,
            fullName,
            proStatus,
            blockState,
            userId,
            referrerCode
          );
          setHeader(res.data.accessToken);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loggedIn]);

  useEffect(() => {
    moment.tz.setDefault(userAccountDetail?.timeZone?.name);
  }, [userAccountDetail]);

  useEffect(() => {
    if (isMobile && !pathname.includes(PATH_AUTH.login) && !isPrimeListerMobileApp) {
      document.body.classList.add('is-mobile-web');
    } else {
      document.body.classList.remove('is-mobile-web');
    }
  }, [isMobile, pathname, isPrimeListerMobileApp]);

  // If not logged in, navigate to login or verification
  if (pathname === '/reports') {
    return <Navigate to={PATH_DASHBOARD.root} replace />;
  }

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <Router />
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
