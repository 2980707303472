import { Divider, Stack } from '@mui/material';
import PartyShareOptions from './PartyShareOptions';
import SharingSpeed from './SharingSpeed';

const PartyShareSettings = () => (
    <Stack spacing={2.5} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
      <SharingSpeed />
      <PartyShareOptions />
    </Stack>
  );

export default PartyShareSettings;
