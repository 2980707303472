import {
  Alert,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from '../../../../types/types';
import RelistLimit from './RelistLimit';
import { Automation } from '@beta.limited/primelister';
import RelistingHours from './RelistHours';

const RelistSettings = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const [isNumberOfPeopleToggled, setIsNumberOfPeopleToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);

  const initialAgesEnabled =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.age.enabled || true;

  const initialAgeValue =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.age.value || 3;

  const initialLikesEnabled =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.likes.enabled || false;

  const initialLikesNumber =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.likes.value || 0;

  const validationSchema = Yup.object().shape({
    initialLikesNumber: Yup.number()
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
    initialAgeValue: Yup.number()
      .required('Please enter a number that is between 3 and 365.')
      .min(3, 'Please enter a number that is between 3 and 365.')
      .max(365, 'Please enter a number that is between 3 and 365.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
  });

  const handleRelistFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      initialLikesNumber,
      initialLikesEnabled,
      initialAgesEnabled,
      initialAgeValue,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const handleAgesInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleRelistFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue >= 3 && inputValue <= 365;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_RELIST]: {
              config: {
                filters: {
                  age: {
                    value: inputValue,
                  },
                },
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !== handleRelistFormik.initialValues.initialLikesNumber;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  const handleLikesEnableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    if (hasActiveClosetConnection) {
      handleRelistFormik.setFieldValue('initialLikesEnabled', newValue);
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_RELIST]: {
            config: {
              filters: {
                likes: {
                  enabled: newValue,
                },
              },
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };
      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const handleLikesInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleRelistFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue >= 0 && inputValue < 100000;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_RELIST]: {
              config: {
                filters: {
                  likes: {
                    value: inputValue,
                  },
                },
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !== handleRelistFormik.initialValues.initialLikesNumber;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  useEffect(
    () => () => {
      clearTimeout(onChangeTimer.current);
    },
    []
  );

  useEffect(() => {
    handleRelistFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const handleInfoIconClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const renderAlert = (errorText: string) => {
    if (renderError) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {errorText}
        </Alert>
      );
    }
  };

  const renderEnableAgesSwitch = () =>
    isNumberOfPeopleToggled && (
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          checked={handleRelistFormik.values.initialAgesEnabled}
          key={'initialAgesEnabled'}
          control={
            <Switch
              name={'initialAgesEnabled'}
              value={handleRelistFormik.values.initialAgesEnabled}
              disabled
            />
          }
          label={
            <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
              {' '}
              <Typography fontSize="14px" color="#212B36">
                Filter by age (in days) of listing
              </Typography>
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title="ℹ️ Set the automation to re-list only the listings older than the age inputted or more"
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );
  const isThereAnErrorOnAge = handleRelistFormik.errors.initialAgeValue && renderError;

  const renderAgeOptionInput = () =>
    isNumberOfPeopleToggled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '13px !important',
          }}
        >
          Listings older than
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 3-365."
            placement={'top'}
          >
            <TextField
              type="number"
              name="initialAgeValue"
              sx={{
                width: 0.22,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnErrorOnAge ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnErrorOnAge ? '#B71C19' : '#000000'}`,
                },
              }}
              value={handleRelistFormik.values.initialAgeValue}
              onChange={handleAgesInputChange}
            />
          </Tooltip>
          days
        </Stack>
        {handleRelistFormik.errors.initialAgeValue &&
          renderAlert(handleRelistFormik?.errors?.initialAgeValue as string)}
      </>
    );

  const renderEnableLikesSwitch = () =>
    isNumberOfPeopleToggled && (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: '8px !important',
        }}
      >
        <FormControlLabel
          checked={handleRelistFormik.values.initialLikesEnabled}
          key={'initialLikesEnabled'}
          control={
            <Switch
              name={'initialLikesEnabled'}
              value={handleRelistFormik.values.initialLikesEnabled}
              onChange={handleLikesEnableChange}
            />
          }
          label={
            <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
              {' '}
              Filter by number of likes of listing
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title="ℹ️ Set the automation to re-list only the listings with the selected number of likes or fewer."
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );

  const isThereAnError = handleRelistFormik.errors.initialLikesNumber && renderError;

  const renderFollowCountInputField = () =>
    isNumberOfPeopleToggled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '13px !important',
          }}
        >
          Listings with
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 0-99999."
            placement={'top'}
          >
            <TextField
              type="number"
              name="initialLikesNumber"
              sx={{
                width: 0.22,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                },
              }}
              value={handleRelistFormik.values.initialLikesNumber}
              onChange={handleLikesInputChange}
            />
          </Tooltip>
          or fewer likes
        </Stack>
        {handleRelistFormik.errors.initialLikesNumber &&
          renderAlert(handleRelistFormik?.errors?.initialLikesNumber as string)}
      </>
    );

  const shouldRenderAgeOptions = handleRelistFormik.values.initialAgesEnabled;
  const shouldRenderLikeInput = handleRelistFormik.values.initialLikesEnabled;

  return (
    <Stack spacing={2} px={matches ? 0 : 1.5}>
      <RelistLimit />
      <Divider sx={{ borderStyle: 'dashed' }} />
      <RelistingHours />
      <Divider sx={{ borderStyle: 'dashed', mt: '20px!important' }} />
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Filters
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Customize Re-list automation by turning on filters. Only listings that match your criteria will be re-listed."
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsNumberOfPeopleToggled(!isNumberOfPeopleToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify
            icon={isNumberOfPeopleToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        </IconButton>
      </Stack>
      {renderEnableAgesSwitch()}
      {shouldRenderAgeOptions && renderAgeOptionInput()}
      {renderEnableLikesSwitch()}
      {shouldRenderLikeInput && renderFollowCountInputField()}
    </Stack>
  );
};

export default RelistSettings;
