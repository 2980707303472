import {
  Alert,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';

const RelistSettings = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const [isNumberOfPeopleToggled, setIsNumberOfPeopleToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);

  const initialShareLimitEnabled =
    automationsSettings[Automation.AUTO_PARTY_SHARE]?.config.shareLimit || false;

  const initialShareContinuouslyEnabled =
    automationsSettings[Automation.AUTO_PARTY_SHARE]?.config.shareContinuously || false;

  const initialShareLimitCount =
    automationsSettings[Automation.AUTO_PARTY_SHARE]?.config.shareLimitCount || 0;

  const initialShareLoopCount =
    automationsSettings[Automation.AUTO_PARTY_SHARE]?.config.shareLoopCount || 0;

  const validationSchema = Yup.object().shape({
    initialShareLimitCount: Yup.number()
      .required('Please enter a number that is between 1 and 99999.')
      .min(1, 'Please enter a number that is between 1 and 99999.')
      .max(99999, 'Please enter a number that is between 1 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
    initialShareLoopCount: Yup.number()
      .required('Please enter a number that is between 1 and 99999.')
      .min(1, 'Please enter a number that is between 1 and 99999.')
      .max(99999, 'Please enter a number that is between 1 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
  });

  const handlePartyShareOptionsFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      initialShareLimitCount,
      initialShareLimitEnabled,
      initialShareContinuouslyEnabled,
      initialShareLoopCount,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const handleShareLimitEnableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    if (hasActiveClosetConnection) {
      handlePartyShareOptionsFormik.setFieldValue('initialShareLimitEnabled', newValue);
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [selectedAutomationOption.name]: {
            config: {
              shareLimit: newValue,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };
      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const handleShareLoopContinuouslyEnableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    if (hasActiveClosetConnection) {
      handlePartyShareOptionsFormik.setFieldValue('initialShareContinuouslyEnabled', newValue);
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [selectedAutomationOption.name]: {
            config: {
              shareContinuously: newValue,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };
      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const handleShareLimitInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handlePartyShareOptionsFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue > 0 && inputValue < 100000;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [selectedAutomationOption.name]: {
              config: {
                shareLimitCount: parseInt(event.target.value),
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !==
          handlePartyShareOptionsFormik.initialValues.initialShareLimitCount;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  const handleShareLoopInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handlePartyShareOptionsFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue > 0 && inputValue < 100000;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [selectedAutomationOption.name]: {
              config: {
                shareLoopCount: parseInt(event.target.value),
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !==
          handlePartyShareOptionsFormik.initialValues.initialShareLoopCount;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  useEffect(
    () => () => {
      clearTimeout(onChangeTimer.current);
    },
    []
  );

  useEffect(() => {
    handlePartyShareOptionsFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const renderAlert = () => {
    const errorMessage =
      handlePartyShareOptionsFormik.errors.initialShareLimitCount ||
      handlePartyShareOptionsFormik.errors.initialShareLoopCount;
    if (renderError) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {errorMessage as string}
        </Alert>
      );
    }
  };

  const handleInfoIconClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const renderEnableShareLimitSwitch = () =>
    isNumberOfPeopleToggled && (
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          checked={handlePartyShareOptionsFormik.values.initialShareLimitEnabled}
          key={'initialShareLimitEnabled'}
          control={
            <Switch
              name={'initialShareLimitEnabled'}
              value={handlePartyShareOptionsFormik.values.initialShareLimitEnabled}
              onChange={handleShareLimitEnableChange}
            />
          }
          label={
            <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
              Share a limited number of items
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title="ℹ️ Limit the number of listings shared by the automation."
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );

  const renderEnableShareLoopSwitch = () =>
    isNumberOfPeopleToggled && (
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          checked={handlePartyShareOptionsFormik.values.initialShareContinuouslyEnabled}
          key={'initialShareContinuouslyEnabled'}
          control={
            <Switch
              name={'initialShareContinuouslyEnabled'}
              value={handlePartyShareOptionsFormik.values.initialShareContinuouslyEnabled}
              onChange={handleShareLoopContinuouslyEnableChange}
            />
          }
          label={
            <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
              Share continuously
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title="ℹ️ Enable continuous sharing of your listings to the party."
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );

  const isThereAnErrorOnLimit =
    handlePartyShareOptionsFormik.errors.initialShareLimitCount && renderError;
  const isThereAnErrorOnLoop =
    handlePartyShareOptionsFormik.errors.initialShareLoopCount && renderError;

  const renderShareLimitCountInputField = () =>
    isNumberOfPeopleToggled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '13px !important',
          }}
        >
          Share up to
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 1-99999."
            placement={'top'}
          >
            <TextField
              type="number"
              name="initialShareLimitCount"
              sx={{
                width: 0.22,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnErrorOnLimit ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnErrorOnLimit ? '#B71C19' : '#000000'}`,
                },
              }}
              value={handlePartyShareOptionsFormik.values.initialShareLimitCount}
              onChange={handleShareLimitInputChange}
            />
          </Tooltip>
          items
        </Stack>
        {isThereAnErrorOnLimit && renderAlert()}
      </>
    );

  const renderShareLoopCountInputField = () =>
    isNumberOfPeopleToggled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '13px !important',
          }}
        >
          Loop repetition count
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 1-99999."
            placement={'top'}
          >
            <TextField
              type="number"
              name="initialShareLoopCount"
              sx={{
                width: 0.22,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnErrorOnLoop ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnErrorOnLoop ? '#B71C19' : '#000000'}`,
                },
              }}
              value={handlePartyShareOptionsFormik.values.initialShareLoopCount}
              onChange={handleShareLoopInputChange}
            />
          </Tooltip>
          laps
        </Stack>
        {isThereAnErrorOnLoop && renderAlert()}
      </>
    );

  const shouldRenderShareLimitInput = handlePartyShareOptionsFormik.values.initialShareLimitEnabled;
  const shouldRenderShareLoopInput =
    handlePartyShareOptionsFormik.values.initialShareContinuouslyEnabled;

  return (
    <Stack spacing={2} px={matches ? 0 : 1.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Options
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Customize your preferences for the Party Share automation."
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsNumberOfPeopleToggled(!isNumberOfPeopleToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify
            icon={isNumberOfPeopleToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        </IconButton>
      </Stack>

      {renderEnableShareLimitSwitch()}
      {shouldRenderShareLimitInput && renderShareLimitCountInputField()}
      {renderEnableShareLoopSwitch()}
      {shouldRenderShareLoopInput && renderShareLoopCountInputField()}
    </Stack>
  );
};

export default RelistSettings;
