import {
  Alert,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';

const SharingSpeed = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const [isShareSpeedToggled, setIsShareSpeedToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);

  const minShareSpeedFromDB = automationsSettings[Automation.AUTO_PARTY_SHARE]
    ? automationsSettings[Automation.AUTO_PARTY_SHARE].config.shareSpeed.min
    : 0;
  const maxShareSpeedFromDB = automationsSettings[Automation.AUTO_PARTY_SHARE]
    ? automationsSettings[Automation.AUTO_PARTY_SHARE].config.shareSpeed.max
    : 0;

  const isFast = minShareSpeedFromDB === 1 && maxShareSpeedFromDB === 2;
  const isMedium = minShareSpeedFromDB === 2 && maxShareSpeedFromDB === 4;
  const isSlow = minShareSpeedFromDB === 4 && maxShareSpeedFromDB === 10;

  let adjustedRadioSelectionValue = 4;

  if (isFast) {
    adjustedRadioSelectionValue = 1;
  } else if (isMedium) {
    adjustedRadioSelectionValue = 2;
  } else if (isSlow) {
    adjustedRadioSelectionValue = 3;
  }

  const initialSpeedRadioSelectionValue = adjustedRadioSelectionValue;

  const isCustom =
    !isFast && !isMedium && !isSlow && (minShareSpeedFromDB !== 0 || maxShareSpeedFromDB !== 0);

  const initialMinShareSpeed = isCustom ? minShareSpeedFromDB : 1;
  const initialMaxShareSpeed = isCustom ? maxShareSpeedFromDB : 5;

  const validationSchema = Yup.object().shape({
    initialMinShareSpeed: Yup.number()
      .required('Please enter a number that is between 1 and 99999.')
      .min(1, 'Please enter a number that is between 1 and 99999.')
      .max(99999, 'Please enter a number that is between 1 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      )
      .test(
        'min-less-than-max',
        'Minimum share speed must be less than or equal to maximum share speed',
        function (value) {
          const { initialMaxShareSpeed } = this.parent;
          return Number(value) < Number(initialMaxShareSpeed);
        }
      ),
    initialMaxShareSpeed: Yup.number()
      .required('Please enter a number that is between 1 and 99999.')
      .min(1, 'Please enter a number that is between 1 and 99999.')
      .max(99999, 'Please enter a number that is between 1 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      )
      .test(
        'max-less-than-min',
        'Minimum share speed must be less than or equal to maximum share speed',
        function (value) {
          const { initialMinShareSpeed } = this.parent;
          return Number(value) > Number(initialMinShareSpeed);
        }
      ),
  });

  const handleSharingSpeedFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      initialMinShareSpeed,
      initialMaxShareSpeed,
      initialSpeedRadioSelectionValue,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const handleSpeedOptionsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ) => {
    if (hasActiveClosetConnection) {
      handleSharingSpeedFormik.handleChange(event);

      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const minValue =
        Number(newValue) === 1 ? 1 : Number(newValue) === 2 ? 2 : Number(newValue) === 3 ? 4 : 1;
      const maxValue =
        Number(newValue) === 1 ? 2 : Number(newValue) === 2 ? 4 : Number(newValue) === 3 ? 10 : 5;

      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_PARTY_SHARE]: {
            config: {
              shareSpeed: {
                min: minValue,
                max: maxValue,
              },
            },
          },
        },

        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const handleMinSpeedChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleSharingSpeedFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput =
        inputValue > 0 &&
        inputValue < 100000 &&
        inputValue <= handleSharingSpeedFormik.values.initialMaxShareSpeed;

      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_PARTY_SHARE]: {
              config: {
                shareSpeed: {
                  min: parseInt(event.target.value),
                  max: handleSharingSpeedFormik.values.initialMaxShareSpeed,
                },
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !==
          handleSharingSpeedFormik.initialValues.initialMinShareSpeed;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  const handleMaxSpeedChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleSharingSpeedFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput =
        inputValue > 0 &&
        inputValue < 100000 &&
        inputValue >= handleSharingSpeedFormik.values.initialMinShareSpeed;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_PARTY_SHARE]: {
              config: {
                shareSpeed: {
                  min: handleSharingSpeedFormik.values.initialMinShareSpeed,
                  max: parseInt(event.target.value),
                },
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !==
          handleSharingSpeedFormik.initialValues.initialMaxShareSpeed;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };
  useEffect(
    () => () => {
      clearTimeout(onChangeTimer.current);
    },
    []
  );

  useEffect(() => {
    handleSharingSpeedFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const renderAlert = (up?: boolean) => {
    const marginUp = matches ? '30px !important' : '20px !important';
    const marginDown = matches ? '48px !important' : '20px !important';
    const alertMarginLeft = up ? marginUp : marginDown;

    if (renderError) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: alertMarginLeft,
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {handleSharingSpeedFormik.errors.initialMinShareSpeed as string}
        </Alert>
      );
    }
  };
  const handleInfoIconClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const renderQuestionIconWithToolrip = (speed: string, x: number, y: number) => (
    <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
      {speed}
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        arrow
        title={`ℹ️ ${x}-${y} seconds.`}
        placement={'top'}
        sx={{ textAlign: 'center !important' }}
      >
        <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
      </Tooltip>
    </Stack>
  );

  const renderAgeOptionRadioSelection = () =>
    isShareSpeedToggled && (
      <Stack
        sx={{
          marginLeft: '10px',
          fontSize: '14px !important',
          marginTop: '12px !important',
        }}
      >
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="initialSpeedRadioSelectionValue"
          value={handleSharingSpeedFormik.values.initialSpeedRadioSelectionValue}
          onChange={handleSpeedOptionsChange}
        >
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={renderQuestionIconWithToolrip('Fast', 1, 2)}
          />

          <FormControlLabel
            value={2}
            control={<Radio />}
            label={renderQuestionIconWithToolrip('Medium', 2, 4)}
          />

          <FormControlLabel
            value={3}
            control={<Radio />}
            label={renderQuestionIconWithToolrip('Slow', 4, 10)}
          />
          <FormControlLabel value={4} control={<Radio />} label="Custom" />
        </RadioGroup>
      </Stack>
    );

  const isThereAnError =
    (handleSharingSpeedFormik.errors.initialMinShareSpeed ||
      handleSharingSpeedFormik.errors.initialMaxShareSpeed) &&
    renderError;

  const renderFollowCountInputField = () =>
    isShareSpeedToggled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginLeft: matches ? '30px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '8px !important',
          }}
        >
          <Stack direction="row" alignItems="center">
            From
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 1-99999."
              placement={'top'}
            >
              <TextField
                type="number"
                name="initialMinShareSpeed"
                sx={{
                  width: 0.42,
                  mx: 1,
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                    color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                  },
                }}
                value={handleSharingSpeedFormik.values.initialMinShareSpeed}
                onChange={handleMinSpeedChange}
              />
            </Tooltip>
            secs
          </Stack>
          <Stack direction="row" alignItems="center" ml={'20px'}>
            To
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 1-99999."
              placement={'top'}
            >
              <TextField
                type="number"
                name="initialMaxShareSpeed"
                sx={{
                  width: 0.45,
                  mx: 1,
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                    color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                  },
                }}
                value={handleSharingSpeedFormik.values.initialMaxShareSpeed}
                onChange={handleMaxSpeedChange}
              />
            </Tooltip>
            secs
          </Stack>
        </Stack>
        {handleSharingSpeedFormik.errors.initialMinShareSpeed && renderAlert(true)}
      </>
    );

  const shouldRenderShareSpeedInputs =
    handleSharingSpeedFormik.values.initialSpeedRadioSelectionValue == 4;

  return (
    <Stack spacing={2} px={matches ? 0 : 1.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Sharing Speed
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Select the sharing speed."
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsShareSpeedToggled(!isShareSpeedToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify icon={isShareSpeedToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        </IconButton>
      </Stack>
      {renderAgeOptionRadioSelection()}
      {shouldRenderShareSpeedInputs && renderFollowCountInputField()}
    </Stack>
  );
};

export default SharingSpeed;
