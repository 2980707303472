import { Stack } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { setIsConnectDialogOpen } from 'src/store/automations/slices/myClosetSlice';
import { setCurrentTab } from 'src/store/dashboard/slices/accountSlice';
import { setStripeReferrerUrl } from 'src/store/dashboard/slices/pricingSlice';
import LayoutAlert, { AlertTypes } from './LayoutAlert';
import useResponsive from 'src/hooks/useResponsive';
import { IRootState } from 'src/store';

interface IAlerts {
  alerts: {
    shouldRenderDuplicateError?: boolean;
    shouldRenderExtensionAlert?: boolean;
    shouldRenderMobileDeviceAlert?: boolean;
    shouldRenderBrowserCompatibilityAlert?: boolean;
    shouldDisplayConnectionAlert?: boolean;
    shouldDisplayReconnectionAlert?: boolean;
    shouldDisplayPoshmarkSubscriptionAlert?: boolean;
  };
  isInAutomations: boolean;
}

const LayoutAlertContainer = ({ alerts, isInAutomations }: IAlerts) => {
  const url = window.location.href;
  const isMobile = useResponsive('down', 'sm');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  //--Alerts
  const {
    shouldDisplayConnectionAlert,
    shouldDisplayReconnectionAlert,
    shouldDisplayPoshmarkSubscriptionAlert,
    shouldRenderDuplicateError,
    shouldRenderExtensionAlert,
    shouldRenderMobileDeviceAlert,
    shouldRenderBrowserCompatibilityAlert,
  } = alerts;

  const handleDuplicateAlertLink = () => {
    dispatch(setCurrentTab('billing'));
    navigate(PATH_DASHBOARD.user.root);
  };

  const handleInstallExtensionAlertButton = () =>
    window.open(
      'https://chrome.google.com/webstore/detail/cross-listing-poshmark-to/eepbhjeldlodgnndnjofcnnoampciipg',
      '_blank',
      'noopener noreferrer'
    );

  const handleCromeBrowserAlertButton = () =>
    window.open('https://www.google.com/chrome/', '_blank', 'noopener noreferrer');

  const handleSubscriptionAlert = () => {
    navigate(PATH_DASHBOARD.subscription.poshmarkAutomation);
    dispatch(setStripeReferrerUrl(url));
  };

  const handleClosetAlert = () => {
    navigate(PATH_AUTOMATIONS.myCloset);
  };

  const handleReconnectAlert = () => {
    navigate(PATH_AUTOMATIONS.myCloset);
  };

  const renderAutomationsAlerts = () => (
    <Stack
      spacing={'16px'}
      sx={{
        mt: '16px',
        mb: !isMobile ? '16px' : '24px',
        p: '0px !important',
      }}
    >
      {shouldDisplayConnectionAlert && (
        <LayoutAlert type={AlertTypes.CONNECTION} alertFunction={handleClosetAlert} />
      )}
      {shouldDisplayReconnectionAlert && (
        <LayoutAlert type={AlertTypes.RECONNECTION} alertFunction={handleReconnectAlert} />
      )}
      {shouldDisplayPoshmarkSubscriptionAlert && (
        <LayoutAlert
          type={AlertTypes.SUBSCRIPTION_TO_POSHMARK}
          alertFunction={handleSubscriptionAlert}
        />
      )}
    </Stack>
  );

  const renderDashboardAlerts = () => (
    <Stack
      spacing={'16px'}
      sx={{
        mt: '16px',
        mb: '16px',
        p: '0px !important',
      }}
    >
      {shouldRenderBrowserCompatibilityAlert && (
        <LayoutAlert
          type={AlertTypes.BROWSER_COMPATIBILITY}
          alertFunction={handleCromeBrowserAlertButton}
        />
      )}
      {shouldRenderDuplicateError && (
        <LayoutAlert type={AlertTypes.DUPLICATE} alertFunction={handleDuplicateAlertLink} />
      )}
      {shouldRenderExtensionAlert && (
        <LayoutAlert
          type={AlertTypes.EXTENSION}
          alertFunction={handleInstallExtensionAlertButton}
        />
      )}
      {shouldRenderMobileDeviceAlert && <LayoutAlert type={AlertTypes.MOBILE_DEVICE} />}
    </Stack>
  );

  const renderContainers = () => {
    if (isInAutomations) {
      return renderAutomationsAlerts();
    } else {
      return renderDashboardAlerts();
    }
  };

  const isInPricingPages = location.pathname.includes('subscription/');

  return isInPricingPages ? null : renderContainers();
};

export default LayoutAlertContainer;
