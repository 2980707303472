import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Stack, Card, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// @types
import { IUserAccountChangePassword } from '../../types/accountTypes';
// components

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import {
  accountChangePasswordFail,
  setIsChangePasswordDialogOpen,
  setPasswordDetails,
} from 'src/store/dashboard/slices/accountSlice';

import ChangePasswordDialog from './ChangePasswordDialog';
import { IRootState } from 'src/store';

// ----------------------------------------------------------------------

type FormValuesProps = IUserAccountChangePassword;

export default function AccountChangePassword() {
  const dispatch = useDispatch();
  const { error } = useSelector((state: IRootState) => state.account);
  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required('Old Password is required')
      .min(6, 'Password must be at least 6 characters'),
    newPassword: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('New Password is required'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('New Password is required'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    watch,
  } = methods;

  //--Button disabling conditions
  const oldPassword = watch('oldPassword');
  const newPassword = watch('newPassword');
  const confirmNewPassword = watch('confirmNewPassword');

  const buttonDisableStep1 = !isDirty;
  const buttonDisableStep2 = oldPassword === '' || newPassword === '' || confirmNewPassword === '';
  const buttonDisableStep3 =
    errors.newPassword !== undefined ||
    errors.oldPassword !== undefined ||
    errors.confirmNewPassword !== undefined;

  const buttonDisabled = buttonDisableStep1 || buttonDisableStep2 || buttonDisableStep3;

  const onSubmit = async (data: FormValuesProps) => {
    await dispatch(setPasswordDetails({ oldPassword, newPassword }));
    dispatch(setIsChangePasswordDialogOpen(true));

    error.length > 0 && dispatch(accountChangePasswordFail(''));

    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Stack spacing={3} alignItems="flex-end" sx={{ p: 3 }}>
            {error.length > 0 && (
              <Alert
                sx={{
                  width: '100%',
                  height: '1.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'flex-start',
                }}
                severity="error"
                variant="standard"
              >
                {error}
              </Alert>
            )}
            <RHFTextField name="oldPassword" type="password" label="Old Password" />
            <RHFTextField
              name="newPassword"
              type="password"
              label="New Password"
              helperText={
                <Stack component="span" direction="row" alignItems="center">
                  <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Password must be at
                  least 6 characters
                </Stack>
              }
            />

            <RHFTextField name="confirmNewPassword" type="password" label="Confirm New Password" />

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={buttonDisabled}
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </Card>
      </FormProvider>
      <ChangePasswordDialog reset={reset} />
    </>
  );
}
