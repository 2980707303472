// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// toastify styles
import 'react-toastify/dist/ReactToastify.min.css';

// ----------------------------------------------------------------------
import { LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, ScrollRestoration } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
// components
import { SettingsProvider } from './components/settings';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
// Check our docs
// https://docs.minimals.cc/authentication/ts-version

//
import App from './App';
// ----------------------------------------------------------------------
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import { ExtensionMessageChannel } from './utils/ExtensionMessageChannel';
import { FLAGSMITH_ENVIRONMENT, MIXPANEL_KEY, SENTRY_ENV } from './config';
import packageJson from '../package.json'; // Update the path accordingly
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';
import SupabaseBroadcast from './utils/SupabaseBroadcast';
// import { FlagsmithProvider } from 'flagsmith/react';
// import flagsmith from 'flagsmith';
import mixpanel from 'mixpanel-browser';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Access the version from the package.json
const { version } = packageJson;
declare global {
  interface Window {
    $crisp: any[];
    CRISP_WEBSITE_ID: string;
    extension: ExtensionMessageChannel;
    supabase: SupabaseBroadcast;
    ReactNativeWebView: any;
    Tally: any;
  }
}

//----------------------------------------------------------------------

const tagManagerArgs = {
  gtmId: 'GTM-MHJX2Z5',
};

TagManager.initialize(tagManagerArgs);

// ----------------------------------------------------------------------

Sentry.init({
  dsn: 'https://694e9f7ede3f440ab066113558e0f4dc@o4503902346477568.ingest.sentry.io/4505039614509056',
  release: version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      //@ts-ignore
      networkDetailAllowUrls: [
        window.location.origin,
        'sandbox-api.primelister.com',
        'api.primelister.com',
        'app.primelister.com',
        'sandbox.primelister.com',
      ],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkCaptureBodies: true,
    }),
  ],
  // TODO: This is a temporary fix, after this is fixed in OpenReplay, remove this line
  ignoreErrors: ['.for is not iterable', `undefined is not a function (near '...[nt,ot]...')`],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: SENTRY_ENV,
});

mixpanel.init(MIXPANEL_KEY as string, {
  debug: false,
  track_pageview: false,
  persistence: 'localStorage',
});

// ----------------------------------------------------------------------
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY || '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ReduxProvider store={store}>
    <HelmetProvider>
      <SettingsProvider>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
          <BrowserRouter>
            <ToastContainer />
            {/* <FlagsmithProvider
            options={{
              environmentID: FLAGSMITH_ENVIRONMENT,
            }}
            flagsmith={flagsmith}
          > */}
            <App />
            {/* </FlagsmithProvider> */}
          </BrowserRouter>
        </LocalizationProvider>
      </SettingsProvider>
    </HelmetProvider>
  </ReduxProvider>
);
