import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Divider,
  Button,
  MenuItem,
  Stack,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';

import MenuPopover from 'src/components/menu-popover';
import { useDispatch, useSelector } from 'react-redux';

import {
  setBulkActionsListingList,
  setCrosslistSourceLoginErrorPlatformsBulk,
  setOpenBulkPopoverMenu,
  setSelectedCheckboxes,
} from 'src/store/dashboard/slices/inventorySlice';

import { IRootState } from 'src/store';
import Iconify from 'src/components/iconify';

import useInventoryBulkActionItems, {
  IBulkMenuActionProps,
} from 'src/hooks/useInventoryBulkActionItems';
import ManageSubscriptionsDialog from './ManageSubscriptionsDialog';

const InventoryBulkActionsMenu = () => {
  const dispatch = useDispatch();

  const {
    selectedCheckboxes,
    openBulkPopoverMenu,
    bulkActionsListingList,
    manageSubscriptionDialogData,
  } = useSelector((state: IRootState) => state.inventory);

  const { actionList1, actionList2, popoverActionsList } = useInventoryBulkActionItems();

  const [isCrosslistHovered, setIsCrosslistHovered] = useState(false);
  const [isRelistHovered, setIsRelistHovered] = useState(false);
  const [isDuplicateHovered, setIsDuplicateHovered] = useState(false);
  const [isDelistHovered, setIsDelistHovered] = useState(false);
  const [isDeleteHovered, setIsDeleteHovered] = useState(false);
  const [isActionHovered, setIsActionHovered] = useState(false);

  const theme = useTheme();

  const handlePopoverCLose = () => dispatch(setOpenBulkPopoverMenu(null));

  const handleBulkActionsButton = (e: React.MouseEvent) => {
    dispatch(setOpenBulkPopoverMenu(e.currentTarget));
    setIsActionHovered(true);
  };

  const checkIsItemHovered = (index: number) => {
    const hoverStates = [
      isCrosslistHovered,
      isRelistHovered,
      isDuplicateHovered,
      isDelistHovered,
      isDeleteHovered,
      isActionHovered,
    ];

    return index >= 0 && index < hoverStates.length ? hoverStates[index] : false;
  };

  const handleOnClose = () => {
    dispatch(setSelectedCheckboxes([]));
    dispatch(setBulkActionsListingList([]));
    dispatch(setCrosslistSourceLoginErrorPlatformsBulk([]));
  };

  const handleButtonMouseInOut = (index: number, isMouseEnter: boolean) => {
    const hoverStateHandlers = [
      setIsCrosslistHovered,
      setIsRelistHovered,
      setIsDuplicateHovered,
      setIsDelistHovered,
      setIsDeleteHovered,
      setIsActionHovered,
    ];

    if (index >= 0 && index < hoverStateHandlers.length) {
      hoverStateHandlers[index](isMouseEnter);
    }
  };

  const shouldOpenBulkActionsMenu = selectedCheckboxes.length > 0;

  const stylesForBulkActionMenu = {
    root: {
      bgcolor: 'rgba(244, 246, 248, 0.95)',
      border: '1px solid #C4CDD5',
      boxShadow: '3px 5px 8px rgba(22, 28, 36, 0.21)',
      backdropFilter: 'blur(1.5px)',
      borderRadius: '8px',
      minWidth: '743px',
      maxWidth: '937px',
      p: '8px 23px 8px 23px',
      zIndex: 19,
      top: ' -82px',
      position: 'absolute',
      transform: 'scale(0)',
      '@keyframes zoomOut': {
        '0%': {
          marginTop: '0rem',
        },
        '100%': {
          marginTop: '50rem',
          transform: 'scale(0)',
        },
      },
      '@keyframes zoomIn': {
        '0%': {
          transform: 'scale(0)',
          marginTop: '50rem',
        },
        '100%': {
          transform: 'scale(1)',
          marginTop: '0rem',
        },
      },
      animation: shouldOpenBulkActionsMenu
        ? 'zoomIn 0.25s ease-out forwards'
        : 'zoomOut 0.65s ease-in forwards',
    },
    firstPart: {
      badge: {
        height: '25px',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '25px',
        p: '0px 6px',
        background: '#3564CA',
        textAlign: 'center',
        color: 'white',
        borderRadius: '4px',
        minWidth: '25px',
      },
      text: {
        fontWeight: '700',
        fontSize: '15px',
        lineHeight: '26px',
        color: 'rgba(33, 43, 54, 1)',
        ml: '16px',
      },
    },
    secondPart: {
      button: (index: number, action: IBulkMenuActionProps) => {
        const styleObject = {
          height: '48px',
          background: '#FFFFFF',
          border: '1px solid #DFE1E6,',
          fontSize: '15px !important',
          borderRadius: ' 8px',
          px: '22px',
          width: checkIsItemHovered(index) ? action.maxWidth : '68px',
          transition: 'width 0.2s ease-in',
          textTransform: 'none',
          overflow: 'hidden',
          '&:hover': { backgroundColor: '#ffffff', color: 'black', scale: 1 },
        };
        return styleObject;
      },
      icon: (index: number, action: IBulkMenuActionProps) => {
        const styleObject = {
          minWidth: action.iconWidth,
          color: 'black',
          width: action.iconWidth,
          height: action.iconHeight,
          ...(checkIsItemHovered(index) && { mr: '8px' }),
        };
        return styleObject;
      },
      span: (index: number, action: IBulkMenuActionProps) => {
        const styleObject = {
          opacity: checkIsItemHovered(index) ? 1 : 0,
          transitionDelay: '0.1s',
          transitionProperty: 'opacity',
          whiteSpace: 'nowrap',
        };
        return styleObject;
      },
    },
    thirdPart: {
      button: (index: number, action: IBulkMenuActionProps) => {
        const styleObject = {
          height: '48px',
          background: '#FFFFFF',
          border: '1px solid #DFE1E6,',
          fontSize: '15px !important',
          borderRadius: ' 8px',
          px: '22px',
          color: '#B71D18',
          width: checkIsItemHovered(index + 3) ? action.maxWidth : action.buttonWidth,
          transition: index === 0 ? 'width 0.2s ease-in' : 'width 0.3s ease-in',
          textTransform: 'none',
          overflow: 'hidden',
          '&:hover': {
            backgroundColor: '#ffffff',
            color: '#B71D18',
          },
        };
        return styleObject;
      },
      icon: (index: number, action: IBulkMenuActionProps) => {
        const styleObject = {
          minWidth: action.iconWidth,
          color: '#B71D18',
          width: action.iconWidth,
          height: action.iconHeight,
          ...(checkIsItemHovered(index + 3) && { mr: '8px' }),
        };
        return styleObject;
      },
      span: (index: number, action: IBulkMenuActionProps) => {
        const styleObject = {
          opacity: checkIsItemHovered(index + 3) ? 1 : 0,
          transitionDelay: index === 0 ? '0.1s' : '0.2s',
          transitionProperty: 'opacity',
          whiteSpace: 'nowrap',
        };
        return styleObject;
      },
    },
    moreActions: {
      button: {
        height: '48px',
        background: '#FFFFFF',
        border: '1px solid #DFE1E6,',
        fontSize: '15px !important',
        borderRadius: ' 8px',
        px: '22px',
        mr: '1px',
        width: checkIsItemHovered(5) || openBulkPopoverMenu ? '171px' : '68px',
        transition: 'width 0.2s ease-in',
        textTransform: 'none',
        scale: 1,
        overflow: 'hidden',
        '&:hover': { backgroundColor: '#ffffff', color: 'black' },
        '&:active': { backgroundColor: '#ffffff', color: 'black' },
        '&:focus': { backgroundColor: '#ffffff', color: 'black' },
      },
      icon: {
        minWidth: '20px',
        color: 'black',
        width: '20px !important',
        height: '16px !important',
        ...((checkIsItemHovered(5) || openBulkPopoverMenu) && { mr: '8px' }),
        '&:hover': { backgroundColor: '#ffffff', color: 'black' },
      },
      span: {
        opacity: checkIsItemHovered(5) || openBulkPopoverMenu ? 1 : 0,
        transitionDelay: '0.1s',
        transitionProperty: 'opacity',
        whiteSpace: 'nowrap',
      },
      menuItem: (action: IBulkMenuActionProps) => {
        const styleObject = {
          fontWeight: '700 !important',
          fontSize: '14px !important',
          pt: '0 !important',
          pb: '0 !important',
          color: action.errorColor ? 'rgba(183, 29, 24, 1)' : 'rgba(33, 43, 54, 1)',
          '&:hover': {
            bgcolor: '#fff',
          },
          height: '48px',
          width: '231px',
        };
        return styleObject;
      },
      menuItemIcon: { width: '20px', height: '20px', borderRadius: 'none', mr: '8px' },
    },
    divider: {
      width: '1px !important',
      height: '48px !important',
    },
  };

  const { root, firstPart, secondPart, thirdPart, moreActions, divider } = stylesForBulkActionMenu;

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={3} sx={root}>
      <Stack direction="row" sx={{ p: 0, m: 0, alignItems: 'center' }}>
        <IconButton
          onClick={handleOnClose}
          sx={{ cursor: 'pointer', borderRadius: '4px', p: '6px', bgcolor: '#DFE3E8', mr: 2 }}
        >
          <Avatar
            src="/icons/closeIcon.svg"
            sx={{
              width: '18px',
              height: '18px',
              borderRadius: '0!important',
            }}
          />
        </IconButton>

        <Typography sx={firstPart.badge}>{bulkActionsListingList.length}</Typography>
        <Typography sx={firstPart.text}>selected</Typography>
      </Stack>

      <Divider orientation="vertical" sx={divider} />

      <Stack direction="row" spacing={2}>
        {actionList1.map((action, index: number) => (
          <Button
            color="inherit"
            key={index}
            sx={secondPart.button(index, action) as {}}
            onClick={action.handleClick}
            onMouseEnter={() => handleButtonMouseInOut(index, true)}
            onMouseLeave={() => handleButtonMouseInOut(index, false)}
          >
            <Iconify icon={action.icon} sx={secondPart.icon(index, action) as {}} />
            <span style={secondPart.span(index, action) as {}}>
              {checkIsItemHovered(index) && action.text}
            </span>
          </Button>
        ))}
      </Stack>

      <Divider orientation="vertical" sx={divider} />

      <Stack direction="row" spacing={2}>
        {actionList2.map((action, index: number) => (
          <Button
            color="inherit"
            key={index * 2}
            sx={thirdPart.button(index, action) as {}}
            onClick={action.handleClick}
            onMouseEnter={() => handleButtonMouseInOut(index + 3, true)}
            onMouseLeave={() => handleButtonMouseInOut(index + 3, false)}
          >
            <Iconify icon={action.icon} sx={thirdPart.icon(index, action) as {}} />
            <span style={thirdPart.span(index, action) as {}}>
              {checkIsItemHovered(index + 3) && action.text}
            </span>
          </Button>
        ))}
      </Stack>

      <Divider orientation="vertical" sx={divider} />

      <Button
        onClick={handleBulkActionsButton}
        color="inherit"
        sx={moreActions.button}
        onMouseEnter={() => handleButtonMouseInOut(5, true)}
        onMouseLeave={() => handleButtonMouseInOut(5, false)}
      >
        <Iconify icon={'eva:more-vertical-fill'} sx={moreActions.icon} />
        <span style={moreActions.span as {}}>
          {(checkIsItemHovered(5) || openBulkPopoverMenu) && 'More Actions'}
        </span>
      </Button>
      <MenuPopover
        open={openBulkPopoverMenu}
        onClose={handlePopoverCLose}
        sx={{
          minWidth: 140,
          bgcolor: 'rgba(244, 246, 248, 0.95)',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disabledArrow
      >
        {popoverActionsList.map((action, index: number) =>
          !action.divider ? (
            <MenuItem
              disabled={action.disabled ?? false}
              key={index}
              sx={moreActions.menuItem(action)}
              onClick={action.handleClick}
            >
              <Avatar variant="square" sx={moreActions.menuItemIcon} src={action.icon} />{' '}
              {action.text}
            </MenuItem>
          ) : (
            <Divider
              key={index}
              orientation="horizontal"
              sx={{
                width: '100%',
                height: '1px',
              }}
            />
          )
        )}
      </MenuPopover>
      {manageSubscriptionDialogData && <ManageSubscriptionsDialog />}
    </Stack>
  );
};

export default InventoryBulkActionsMenu;
