// @mui
import {
  Stack,
  Typography,
  Button,
  Avatar,
  Switch,
  FormControlLabel,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';

import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';

import { useFormik } from 'formik';

import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {
  createAutomationWorkerRequest,
  setAutomationsSettings,
  setIsAutomationOptionDrawerOpen,
  setIsConnectionErrorDialogOpen,
  setIsDisableConfirmationDialogOpen,
  setSelectedAutomationOption,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import { ChangeEvent, useEffect, useState } from 'react';
import { IAutomationOptionForm, IDataForUpdateAutomationSettings } from '../types/types';
import { hideCrispChat } from 'src/utils/crispChatHelpers';
import { Automation, PoshmarkSettings } from '@beta.limited/primelister';
import { MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';
import { getDateWithTimeZone } from '../../../../utils/getDateWithTimezone';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';
import { setShouldOpenExpiredSubscriptionDialog } from 'src/store/automations/slices/myClosetSlice';
export default function AutomationOption({ automation }: IAutomationOptionForm) {
  const isDesktop = useResponsive('up', 'md');
  const dispatch = useDispatch();

  const { automationsSettings, activeClosetAutomationPlatform, isOnBoardingActive, loading } =
    useSelector((state: IRootState) => state.automations);
  const [isEnabled, setIsEnabled] = useState(
    automationsSettings[automation.name as keyof PoshmarkSettings]
      ? automationsSettings[automation.name].enabled
      : false
  );

  const { activeCloset, hasCloset, activeClosetCredentialsId } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { shouldConnectionErrorDialogOpen, isSubscriptionExpired } = useCheckSubscriptionStatus();

  const matches = useMediaQuery('(min-width:500px)');

  const handleAutomationItemEnablingFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isEnabled,
    },
    onSubmit: (values) => {},
  });

  const handleAutomationItemEnablingChange = async (
    event: ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    event.stopPropagation();
    const enableMessage = `${automation.displayName} automation successfully enabled`;
    const disableMessage = `${automation.displayName} automation successfully disabled`;
    const toastMessage = newValue ? enableMessage : disableMessage;

    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [automation.name]: {
          enabled: newValue,
        },
      },
      toastMessage,
      displayName: automation.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
      ...(hasCloset && {
        workerData: {
          automation: automation.name,
          marketplace: activeClosetAutomationPlatform,
          activeClosetCredentialsId,
          status: newValue,
          automationsSettings,
          toastMessage,
        },
      }),
    };

    const mixpanelData = {
      tool: 'poshmarkAutomation',
      source: 'web',
      automation: automation.name,
      enabled: newValue,
      credentialsId: activeClosetCredentialsId,
      marketplace: activeClosetAutomationPlatform,
      country: activeCloset?.country,
      poshmarkUserName: activeCloset?.poshmarkUserName,
    };

    if (newValue) {
      if (shouldConnectionErrorDialogOpen) {
        dispatch(setIsConnectionErrorDialogOpen(true));
      } else if (isSubscriptionExpired) {
        dispatch(setShouldOpenExpiredSubscriptionDialog(true));
      } else {
        mixpanelTrack(MixpanelEventTypes.AUTOMATION_ENABLE_STATUS_CHANGED, mixpanelData);
        dispatch(
          createAutomationWorkerRequest({
            automation: automation.name,
            marketplace: activeClosetAutomationPlatform,
            activeClosetCredentialsId,
            status: newValue,
            toastMessage,
            automationsSettings,
          })
        );
      }
    } else {
      dispatch(setIsDisableConfirmationDialogOpen({ isOpen: true, dataForUpdating }));
    }
  };

  const handleEnableChangeWithLoadingCheck = (
    event: ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => !loading && handleAutomationItemEnablingChange(event, newValue);

  const automationSettings = automationsSettings[automation.name as keyof PoshmarkSettings];

  const automationData =
    automationSettings && 'data' in automationSettings ? automationSettings.data : undefined;

  const lastRunTimeData =
    automationData && 'lastExecutedAt' in automationData ? automationData.lastExecutedAt : null;

  const lastRunTimeFormatted = lastRunTimeData
    ? getDateWithTimeZone(
        lastRunTimeData,
        userAccountDetail?.timeZone?.name,
        'MMM DD, YYYY [at] hh:mm A'
      )
    : null;

  const isAutomationSupported = !['share', 'communityShare'].includes(automation.name);

  const shouldDisplayLastRunInfo = Boolean(lastRunTimeFormatted && isAutomationSupported);

  const handleAutomationOptionSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLInputElement;
    if (target && target.name !== 'isEnabled') {
      dispatch(setSelectedAutomationOption(automation));
      dispatch(setIsAutomationOptionDrawerOpen(true));
      hideCrispChat();
    }
  };

  const handleInfoIconClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const settingsIconBoardingClassName =
    automation.name === Automation.AUTO_FOLLOW_NEW_CLOSETS ? 'driver-first' : '';

  return (
    <Stack
      key={automation.displayName}
      onClick={(event) => !isOnBoardingActive && handleAutomationOptionSelection(event)}
      spacing={isDesktop ? 1.5 : 1}
      direction={'row'}
      alignItems={'center'}
      sx={{
        p: matches ? 1.5 : 0.6,
        borderRadius: 2,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) => theme.customShadows.z20,
        },
        ...(isDesktop && {
          borderRadius: 1.5,
        }),
        cursor: 'pointer',
        minHeight: '70px',
      }}
    >
      <Stack alignSelf="center">
        <Avatar src={automation.url} sx={{ width: 40, height: 40, borderRadius: '0 !important' }} />
      </Stack>

      <Stack
        direction={'row'}
        sx={{
          width: 1,
          flexGrow: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        spacing={matches ? 1.5 : 1}
      >
        <Stack
          spacing={matches ? 0.5 : 0.1}
          sx={{
            flexGrow: { sm: 1 },
            alignItems: 'flex-start',
            justifyContent: 'center',
            minWidth: '167px',
          }}
        >
          <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
            {automation.displayName}{' '}
            <Tooltip
              arrow
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                >
                  ℹ️ {automation.tooltipText}
                </Typography>
              }
              placement={'top'}
              sx={{ textAlign: 'center !important' }}
            >
              <HelpOutlineIcon
                sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }}
                onClick={handleInfoIconClick}
              />
            </Tooltip>
          </Typography>
          {shouldDisplayLastRunInfo && (
            <Stack
              direction={matches ? 'row' : 'column'}
              sx={{ alignItems: matches ? 'center' : 'flex-start' }}
            >
              <Typography variant="caption" color={'#919EAB'}>
                Last Run
              </Typography>

              {matches && (
                <h6
                  style={{
                    border: '1.5px solid',
                    borderRadius: '100px',
                    width: '1px',
                    height: '1px',
                    margin: '0 6px',
                    color: '#919EAB',
                  }}
                >
                  {' '}
                </h6>
              )}

              <Typography variant="caption" color={'#919EAB'}>
                {lastRunTimeFormatted}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            id="dontListenParentsEvent"
            checked={handleAutomationItemEnablingFormik.values.isEnabled}
            key={automation.name}
            control={
              <Switch
                name="isEnabled"
                disabled={loading}
                value={handleAutomationItemEnablingFormik.values.isEnabled}
                onChange={handleEnableChangeWithLoadingCheck}
              />
            }
            label=""
            sx={{ mr: -0.4 }}
          />

          <Tooltip title="Configure the automation" placement={'top'} arrow>
            <Button
              sx={{
                width: '56px',
                height: '36px',
                backgroundColor: '#F6F7F8',
                color: 'black',
              }}
            >
              <SettingsIcon className={settingsIconBoardingClassName} />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
}
