import { Divider, Stack, useMediaQuery } from '@mui/material';

import DailyShareText from './DailyShareText';
import SharingHours from './SharingHours';

const DailyShareSettings = () => {
  const matches = useMediaQuery('(min-width:400px)');

  return (
    <Stack spacing={2} px={matches ? 0 : 1.5} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
      <SharingHours />
      <DailyShareText />
    </Stack>
  );
};

export default DailyShareSettings;
