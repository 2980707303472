// @mui
import { Tooltip, IconButton, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import {
  setOpenCrosslistDialog,
  setOpenPopoverMenu,
  setOpenDownloadExtensionDialog,
  setDeletePopup,
  setPopoverData,
  setCrosslistSourceLoginErrorPlatformQuickMenu,
  setOpenAPIConnectionRequiredDialog,
  officialPlatforms,
  setCrosslistSourceLoginErrorPlatformsBulk,
  setManageSubscriptionDialogData,
  setIsManageSubscriptionsDialogOpen,
} from 'src/store/dashboard/slices/inventorySlice';
import { IInventoryPopoverData, IListingSources } from '../../../types/dataGridInterfaces';
import { setIsNotLoggedInDialogOpen } from 'src/store/dashboard/slices/myShopsSlice';
import usePlatformLoginStatusCheck from 'src/hooks/usePlatformLoginStatusCheck';
import {
  checkBulkListingSourcesLoginStatus,
  checkListingSourcesLoginStatus,
} from 'src/utils/checkListingSourcesLoginStatus';
import { getDisconnectedListings } from '../dataGrid/helpers';
import { IRootState } from 'src/store';
import { IAPIMarketplaces } from 'src/store/dashboard/slices/myShopsAPITableSlice';
import useCheckPermission, { PermissionCheckActionEnum } from 'src/hooks/useCheckPermission';
import useHandleCrosslist from 'src/hooks/useHandleCrosslist';

// ----------------------------------------------------------------------

export default function QuickActionsMenu({ newData }: { newData: IInventoryPopoverData }) {
  const { isExtensionConnected } = useSelector((state: IRootState) => state.home);
  const dispatch = useDispatch();
  const myShopsAPITable = useSelector((state: IRootState) => state.myShopsAPITable);
  const { checkPermission } = useCheckPermission();
  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();

  const handleEdit = () => {
    window.open(
      `${PATH_DASHBOARD.inventory.root + '/' + newData?.id}`,
      '_blank',
      'noopener noreferrer'
    );

    dispatch(setOpenPopoverMenu(null));
  };

  const handleDelete = () => {
    dispatch(setPopoverData(newData));
    dispatch(setOpenPopoverMenu(null));
    dispatch(setDeletePopup(true));
  };

  const { handleCrosslist } = useHandleCrosslist();
  const actions = [
    {
      name: 'Crosslist',
      icon: 'solar:copy-outline',
      action: () =>
        handleCrosslist({
          listingData: [newData],
          isExtensionConnected,
          checkPermission,
          getLoginStatusOfMarketplace,
          isBulk: false,
          onSuccess: () => {
            dispatch(setPopoverData(newData));
            dispatch(setOpenPopoverMenu(null));
          },
        }),
    },
    { name: 'Edit', icon: 'ic:baseline-edit', action: handleEdit },
    { name: 'Delete', icon: 'eva:trash-2-outline', action: handleDelete },
  ];

  return (
    <Paper
      data-quick-menu-id={newData.id}
      variant="outlined"
      sx={{
        my: 'auto',
        minHeight: '37px',
        display: 'none',
        alignItems: 'center',
        py: '2px',
        boxShadow: (theme) => theme.customShadows.z8,
        transition: (theme) => theme.transitions.create('opacity'),
      }}
    >
      {actions.map((action, index) => (
        <Tooltip disableInteractive key={action.name} title={action.name}>
          <IconButton
            size="small"
            onClick={action.action}
            sx={{
              mx: 0.75,
              color: index === 2 ? 'error.main' : 'text.primary',
              opacity: 0.65,
              '&:hover': { opacity: 1 },
            }}
          >
            <Iconify icon={action.icon} width={24} />
          </IconButton>
        </Tooltip>
      ))}
    </Paper>
  );
}
