import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, FormHelperText, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes

import { authNewPasswordRequest, resetAuthError } from 'src/store/dashboard/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';

import FormProvider, { RHFCodes, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { PATH_AUTH } from 'src/routes/paths';

// ----------------------------------------------------------------------

type FormValuesProps = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, emailFromResetPage, isResetSuccessfulDialogOpen, loading } = useSelector(
    (state: IRootState) => state.auth
  );
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const emailFromUrl = params.get('email');
  const code = params.get('code');

  const [showPassword, setShowPassword] = useState(false);

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const defaultValues = {
    code1: code?.charAt(0) || '',
    code2: code?.charAt(1) || '',
    code3: code?.charAt(2) || '',
    code4: code?.charAt(3) || '',
    code5: code?.charAt(4) || '',
    code6: code?.charAt(5) || '',
    email: emailFromResetPage || emailFromUrl || '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      dispatch(
        authNewPasswordRequest({
          payload: {
            email: data.email,
            code: `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`,
            password: data.password,
          },
          navigate: navigate,
        })
      );
    } catch (err) {}
  };

  useEffect(() => {
    if (emailFromUrl && code) {
      setValue('code1', code?.charAt(0));
      setValue('code2', code?.charAt(1));
      setValue('code3', code?.charAt(2));
      setValue('code4', code?.charAt(3));
      setValue('code5', code?.charAt(4));
      setValue('code6', code?.charAt(5));
      setValue('email', emailFromResetPage || emailFromUrl);
    } else if (emailFromUrl || emailFromResetPage) {
      setValue('email', emailFromUrl || emailFromResetPage);
    }
    if (!emailFromUrl && !emailFromResetPage) {
      navigate(PATH_AUTH.forgotPassword);
    }
    dispatch(resetAuthError());
  }, []);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error.length > 0 && (
        <Alert
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'flex-start',
            mb: 3.2,
          }}
          severity="error"
          variant="standard"
        >
          {error}
        </Alert>
      )}
      <Stack spacing={3}>
        <RHFTextField
          name="email"
          label="Email"
          disabled={!!emailFromUrl || !!emailFromResetPage}
          InputLabelProps={{ shrink: true }}
        />

        <RHFCodes
          disabled={!!code}
          keyName="code"
          inputs={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']}
        />

        {(!!errors.code1 ||
          !!errors.code2 ||
          !!errors.code3 ||
          !!errors.code4 ||
          !!errors.code5 ||
          !!errors.code6) && (
          <FormHelperText error sx={{ px: 2 }}>
            Code is required
          </FormHelperText>
        )}

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Confirm New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          sx={{ mt: 3 }}
        >
          Update Password
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
